




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

/**
 * Tooltip component
 *
 * title: string
 * Tooltip content (text only, no HTML). if HTML is required, place it in the tooltip slot
 *
 * placement: string (default: top)
 * Tooltip position, relative to the trigger element
 *
 * trigger: string (default: hover)
 * Trigger open/close of tooltip
 *
 */
@Component({
  inheritAttrs: false,
})
export default class Tooltip extends Vue {
  @Prop({ default: 'hover' }) trigger!: 'hover' | 'click' | 'focus'
  @Prop() title!: string
  @Prop({ default: 'top' }) placement!: 'top' | 'left' | 'right' | 'bottom'
  @Prop({ type: Boolean, default: false }) disable!: boolean

  active = false

  @Watch('disable')
  private isDisable(value: boolean): void {
    if (value) {
      this.active = false
    }
  }

  private checkTrigger(triggerName: string): boolean {
    return this.trigger === triggerName && !this.disable
  }

  private onClick() {
    if (this.checkTrigger('click')) {
      this.active = !this.active
    }
  }

  private onHover() {
    if (this.checkTrigger('hover')) {
      this.active = true
    }
  }

  private onHoverLeave() {
    if (this.checkTrigger('hover')) {
      this.active = false
    }
  }

  private onFocus() {
    if (this.checkTrigger('focus')) {
      this.active = !this.active
    }
  }
}
