import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  CreateCategorySectionRequest,
  RepositionCategorySectionRequest,
  UpdateCategorySectionRequest,
} from '@/data/payload/api/CategorySectionRequest'
import { CategorySectionPresenter } from '@/app/ui/presenters/CategorySectionPresenter'
import { CategorySection } from '@/domain/entities/CategorySection'
import { ProductDropdown } from '@/domain/entities/Product'

export interface CategorySectionState {
  isLoading: boolean
  categorySectionData: CategorySection[]
  categorySectionDetail: CategorySection
  productDropdown: ProductDropdown[]
  isProductLoading: boolean
}

interface Form {
  categoryName: string
  bannerHomepageImage: Blob | null
  bannerDetailImage: Blob | null
  products: number[]
}

interface FormEdit extends Form {
  id: number
}

@Module({ namespaced: true, store, name: 'categorySection', dynamic: true })
class CategorySectionController extends VuexModule
  implements CategorySectionState {
  private presenter: CategorySectionPresenter = container.resolve(
    CategorySectionPresenter
  )
  public isLoading = false
  public categorySectionData: CategorySection[] = []
  public categorySectionDetail = new CategorySection()
  public productDropdown: ProductDropdown[] = []
  public isProductLoading = false
  public statusRepositionCategorySection = ''
  public statusDeleteCategorySection = ''
  public statusCreateCategorySection = ''
  public statusEditCategorySection = ''

  @Action({ rawError: true })
  public createCategorySection(form: Form): void {
    this.setLoading(true)

    this.presenter
      .create(
        new CreateCategorySectionRequest(
          form.categoryName,
          <Blob>form.bannerHomepageImage,
          <Blob>form.bannerDetailImage,
          form.products
        )
      )
      .then(() => {
        this.setStatusCreateCategorySection(EventBusConstants.CREATE_CATEGORY_SECTION_SUCCESS)
        EventBus.$emit(EventBusConstants.CREATE_CATEGORY_SECTION_SUCCESS, {})
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Category Section Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateCategorySection(form: FormEdit): void {
    this.setLoading(true)

    this.presenter
      .update(
        form.id,
        new UpdateCategorySectionRequest(
          form.categoryName,
          <Blob>form.bannerHomepageImage,
          <Blob>form.bannerDetailImage,
          form.products
        )
      )
      .then(() => {
        this.setStatusEditCategorySection(EventBusConstants.UPDATE_CATEGORY_SECTION_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Category Section Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCategorySectionList(): void {
    this.setLoading(true)

    this.presenter
      .getAll()
      .then(res => {
        this.setCategorySectionData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Category Section Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getCategorySectionDetail(id: number): void {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setCategorySectionDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Category Section Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteCategorySection(categorySectionId: number): void {
    this.setLoading(true)

    this.presenter
      .delete(categorySectionId)
      .then(() => {
        this.setStatusDeleteCategorySection(EventBusConstants.DELETE_CATEGORY_SECTION_SUCCESS)

        this.setCategorySectionData(
          this.categorySectionData.filter(
            category => category.id !== categorySectionId
          )
        )
      })
      .catch(error => {
        Vue.notify({
          title: 'Delete Category Section Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getProductDropdown(
    params: Record<string, string | number | undefined>
  ): void {
    this.setProductLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getProducts(formattedParams)
      .then(res => {
        this.setProductDropdown(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Product Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setProductLoading(false)
      })
  }

  @Action({ rawError: true })
  public repositionCategorySection(sections: number[]): void {
    this.setLoading(true)

    this.presenter
      .reposition(
        new RepositionCategorySectionRequest(sections)
      )
      .then(() => {
        this.setStatusRepositionCategorySection(EventBusConstants.REPOSITION_CATEGORY_SECTION_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Reposition Category Section Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  public setCategorySectionData(categorySections: CategorySection[]): void {
    this.categorySectionData = categorySections
  }

  @Mutation
  private setCategorySectionDetail(categorySection: CategorySection): void {
    this.categorySectionDetail = categorySection
  }

  @Mutation
  private setProductDropdown(products: ProductDropdown[]): void {
    this.productDropdown = products
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setProductLoading(bool: boolean): void {
    this.isProductLoading = bool
  }

  @Mutation
  public setStatusRepositionCategorySection(status: string): void {
    this.statusRepositionCategorySection = status
  }

  @Mutation
  public setStatusDeleteCategorySection(status: string): void {
    this.statusDeleteCategorySection = status
  }

  @Mutation
  public setStatusCreateCategorySection(status: string): void {
    this.statusCreateCategorySection = status
  }

  @Mutation
  public setStatusEditCategorySection(status: string): void {
    this.statusEditCategorySection = status
  }
}

export default getModule(CategorySectionController)
