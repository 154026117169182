import {
  CreateCategorySectionRequestInterface,
  UpdateCategorySectionRequestInterface,
  RepositionCategorySectionRequestInterface,
} from '@/data/payload/contracts/CategorySectionRequest'

export class CreateCategorySectionRequest
  implements CreateCategorySectionRequestInterface {
  public categoryName?: string
  public bannerHomepageImage?: Blob
  public bannerDetailImage?: Blob
  public products?: number[]

  constructor(
    categoryName?: string,
    bannerHomepageImage?: Blob,
    bannerDetailImage?: Blob,
    products?: number[]
  ) {
    this.categoryName = categoryName
    this.bannerHomepageImage = bannerHomepageImage
    this.bannerDetailImage = bannerDetailImage
    this.products = products
  }

  public toPayload(): FormData {
    const data = new FormData()
    data.append('category_name', this.categoryName as string)
    data.append('banner_homepage_image', this.bannerHomepageImage as Blob)
    data.append('banner_detail_image', this.bannerDetailImage as Blob)
    data.append('list_product_id', this.products?.join(',') || '')

    return data
  }
}

export class UpdateCategorySectionRequest
  implements UpdateCategorySectionRequestInterface {
  public categoryName?: string
  public bannerHomepageImage?: Blob
  public bannerDetailImage?: Blob
  public products?: number[]

  constructor(
    categoryName?: string,
    bannerHomepageImage?: Blob,
    bannerDetailImage?: Blob,
    products?: number[]
  ) {
    this.categoryName = categoryName
    this.bannerHomepageImage = bannerHomepageImage
    this.bannerDetailImage = bannerDetailImage
    this.products = products
  }

  public toPayload(): FormData {
    const data = new FormData()
    data.append('category_name', this.categoryName as string)
    data.append('banner_homepage_image', this.bannerHomepageImage as Blob)
    data.append('banner_detail_image', this.bannerDetailImage as Blob)
    data.append('list_product_id', this.products?.join(',') || '')

    return data
  }
}

export class RepositionCategorySectionRequest
  implements RepositionCategorySectionRequestInterface {
  public sectionIds?: number[]

  constructor(sectionIds: number[]) {
    this.sectionIds = sectionIds
  }

  public toPayload(): string {
    return JSON.stringify({
      list_section_id: this.sectionIds,
    })
  }
}
