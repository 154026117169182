





































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
})

/**
 * description: string
 * Show input description
 *
 * showPreview: boolean
 * Show image preview
 *
 * preview: string
 * Default image preview
 *
 * maxFileSize: number
 * Max file size in KB
 */
export default class FileInput extends Vue {
  @Prop({ type: String }) private description?: string
  @Prop({ type: Boolean, default: true }) private showPreview?: boolean
  @Prop({ type: String, default: '' }) private preview?: string
  @Prop({ type: String }) private accept?: string
  @Prop({ type: Number, default: 1 }) private maxFileSize!: number // in KB

  fileName: string | null = null
  base64Preview: string | null = null
  error = ''

  get imagePreview(): string {
    if (this.base64Preview) {
      return this.base64Preview
    }

    return <string>this.preview
  }

  private onFileChange(event: Event) {
    const target = <HTMLInputElement>event.target
    try {
      if (target.files) {
        if (
          this.accept &&
          !this.accept.split(',').includes(target.files[0].type)
        ) {
          throw new Error(`File Type must be in ${this.accept}`)
        }

        if (target.files[0].size / 1024 > this.maxFileSize) {
          throw new Error(`File size exceeds ${this.maxFileSize}KB`)
        }

        this.error = ''

        this.fileName = target.files[0].name

        // file reader
        const reader = new FileReader()
        reader.onload = e => {
          this.base64Preview = <string>e.target?.result

          this.$emit('base64Preview', <string>e.target?.result)
        }
        reader.readAsDataURL(target.files[0])
        // end of file reader

        this.$emit('change', target.files[0])
      }
    } catch (e) {
      const error = <{ message: string }>e
      this.error = error.message

      // Show error alert
      this.$notify({
        title: 'Error',
        text: error.message,
        type: 'error',
        duration: 3000,
      })
    }
  }
}
